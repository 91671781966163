import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogGlossaryComponent } from 'app/dialog/dialog-glossary/dialog-glossary.component';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ticket-editor',
  templateUrl: './ticket-editor.component.html',
  styleUrls: ['./ticket-editor.component.scss']
})
export class TicketEditorComponent implements OnInit {
  @ViewChild('createTicketTab') createTicketTab: ElementRef<HTMLElement>;
  @ViewChild('createCombinationTab') createCombinationTab: ElementRef<HTMLElement>;
  @ViewChild('createReservationTab') createReservationTab: ElementRef<HTMLElement>;
  @ViewChild('createCrewAccountTab') createCrewAccountTab: ElementRef<HTMLElement>;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); // array subscription
  tabSelected: any = Constant.APPLICATION_ENUM; // tab selected
  isEnlargePreviewReservation: boolean = true; // true if expand preview
  isEnlargePreviewTabApplication: boolean = false; // true if preview mode is enabled while on the application tab
  isEnlargePreviewTabCombination: boolean = false; //true if preview mode is enabled while on the combinationm tab
  isEnlargePreviewTabTicket: boolean = false; // true if preview mode is enabled while on the ticket tab
  informationAccount: any;
  languageTranslation: string[];
  companies: any;
  isRoot: boolean;
  fileGlossary: string;
  mapImageEnvironmentVariables: Map<string, number> = new Map<string, number>();
  languageKey: string;

  constructor(
    private dataService: DataService,
    public datepipe: DatePipe,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private ticketService: TicketEditorService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private commonTableService: CommonTableService
  ) {
    this.subscriptions.push(
      // Action add
      this.menuActionService.actionGlossary.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent]) {
          this.glossary();
        }
      })
    );
    this.isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
    this.languageKey = this.commonService.getCommonObject()?.setting?.language;
    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
    });
  }

  ngOnInit(): void {
    this.ticketService.getInformationAccount().subscribe(data => {
      this.informationAccount = data;
      this.dataService.sendData([Constant.IS_TAB_APPLICATION, true]);
      this.ticketService.getCompanies(this.informationAccount).subscribe(companies => {
        this.companies = companies;
      });
    });
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_GLOSSARY_TICKET).subscribe(data => {
      if (data) {
        this.fileGlossary = Object.keys(JSON.parse(data.value))[0];
      }
    });
    this.ticketService.getImageEnvironmentVariables().subscribe(res => {
      this.mapImageEnvironmentVariables = new Map<string, number>(Object.entries(res));
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * choose tab
   * @param tabEnum
   */
  chooseTab(tabEnum: any): void {
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
    this.dataService.sendData([Constant.IS_EDITING_COMBINATION, false]);
    this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, false]);
    this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
    if (this.tabSelected == tabEnum) {
      return;
    }

    this.tabSelected = tabEnum;
    switch (this.tabSelected) {
      case Constant.TICKET_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_APPLICATION, tabEnum == Constant.APPLICATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_TICKET, tabEnum == Constant.TICKET_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_COMBINATION, tabEnum == Constant.COMBINATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, tabEnum == Constant.RESERVATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_CREW_ACCOUNT, tabEnum == Constant.CREW_ACCOUNT_ENUM]);
        break;
      }
      case Constant.COMBINATION_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_APPLICATION, tabEnum == Constant.APPLICATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_TICKET, tabEnum == Constant.TICKET_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_COMBINATION, tabEnum == Constant.COMBINATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, tabEnum == Constant.RESERVATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_CREW_ACCOUNT, tabEnum == Constant.CREW_ACCOUNT_ENUM]);
        break;
      }
      case Constant.RESERVATION_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_APPLICATION, tabEnum == Constant.APPLICATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_TICKET, tabEnum == Constant.TICKET_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_COMBINATION, tabEnum == Constant.COMBINATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, tabEnum == Constant.RESERVATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_CREW_ACCOUNT, tabEnum == Constant.CREW_ACCOUNT_ENUM]);
        break;
      }
      case Constant.CREW_ACCOUNT_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_APPLICATION, tabEnum == Constant.APPLICATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_TICKET, tabEnum == Constant.TICKET_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_COMBINATION, tabEnum == Constant.COMBINATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, tabEnum == Constant.RESERVATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_CREW_ACCOUNT, tabEnum == Constant.CREW_ACCOUNT_ENUM]);
        break;
      }
      default: {
        this.dataService.sendData([Constant.IS_TAB_APPLICATION, tabEnum == Constant.APPLICATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_TICKET, tabEnum == Constant.TICKET_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_COMBINATION, tabEnum == Constant.COMBINATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, tabEnum == Constant.RESERVATION_ENUM]);
        this.dataService.sendData([Constant.IS_TAB_CREW_ACCOUNT, tabEnum == Constant.CREW_ACCOUNT_ENUM]);
        break;
      }
    }
  }

  /**
   * switch tab
   * @param tabEnum
   */
  switchTab(tab): void {
    this.dataService.sendData([tab, true]);
  }

  /**
   * enlarge preview reservation
   */
  enlargePreviewReservation(): void {
    this.isEnlargePreviewReservation = !this.isEnlargePreviewReservation;
  }

  /**
   * enlarge preview application
   */
  enlargePreviewApplication(): void {
    this.isEnlargePreviewTabApplication = !this.isEnlargePreviewTabApplication;
  }

  /**
   * enlarge preview combination
   */
  enlargePreviewCombination(): void {
    this.isEnlargePreviewTabCombination = !this.isEnlargePreviewTabCombination;
  }

  /**
   * enlarge preview ticket
   */
  enlargePreviewTicket(): void {
    this.isEnlargePreviewTabTicket = !this.isEnlargePreviewTabTicket;
  }

  /**
   * Set width menu reservation element
   */
  setWidthMenuReservationElement(): string {
    if (+sessionStorage.getItem(Constant.LAYOUT) == 0) {
      let width = '';
      if (this.tabSelected == Constant.RESERVATION_ENUM) {
        width = this.isEnlargePreviewReservation ? (width = 'calc(100% - 710px)') : 'calc(100% - 70px)';
      } else if ([Constant.SPOT_ENUM, Constant.CREW_ACCOUNT_ENUM].includes(this.tabSelected)) {
        width = '100%';
      }
      return width;
    } else {
      let width = '';
      if (this.tabSelected == Constant.RESERVATION_ENUM) {
        width = this.isEnlargePreviewReservation ? (width = '1190px') : '1830px';
      } else if ([Constant.SPOT_ENUM, Constant.CREW_ACCOUNT_ENUM].includes(this.tabSelected)) {
        width = '100%';
      }
      return width;
    }
  }

  /**
   * glossary
   */
  public glossary(): void {
    this.dialogService.showDialog(
      DialogGlossaryComponent,
      {
        data: {
          screen: Constant.TICKET_EDITOR_COMPONENT_URL
        }
      },
      result => {
        this.fileGlossary = result;
      }
    );
  }

  /**
   * receiveLanguageTranslation
   * @param language
   */
  receiveLanguageTranslation(language: string[]): void {
    this.languageTranslation = language;
  }
}
